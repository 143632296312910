const HOST_PANEL_PREVIEW_VERSION = 'HOST_PANEL_PREVIEW_VERSION';

function getUrlQueryParam(key: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

function setUrlQueryParam(key: string, value: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.set(key, value);
}

function getLocalStorageValue(key: string) {
  let item = null;
  if (window && window.localStorage) {
    item = window.localStorage.getItem(key);
  }

  return item;
}

function setLocalStorageValue(key: string, value: any) {
  if (window && window.localStorage) {
    window.localStorage.setItem(key, value);
  }
}

// Depending on query param load the correct bundle file.
function initLauncher() {
  console.log('[Launcher]: Started Host Panel Launcher');
  let version = getUrlQueryParam('version') || getLocalStorageValue(HOST_PANEL_PREVIEW_VERSION);

  let script = document.createElement('script');
  script.setAttribute('id', 'app');
  script.type = 'text/javascript';
  script.src = '/app.js';

  if (version) {
    setLocalStorageValue(HOST_PANEL_PREVIEW_VERSION, version);
    // Only load the versioned mode when we are not in development, to allow development on features with the versions
    if (process.env.NODE_ENV !== 'development') {
      script.src = '/versions/' + version + '/app.js';

      function customAppendChild(this: Element, childNode: Element) {
        if (childNode.tagName === 'SCRIPT' && !!childNode.getAttribute('data-webpack')) {
          const src = childNode.getAttribute('src');
          const adjustedSrc = src.replace(/\/([^\/]+\.js)$/, `/versions/${version}/$1`);

          childNode.setAttribute('src', adjustedSrc);
        }

        return Node.prototype.appendChild.call(this, childNode);
      }

      // @ts-ignore
      document.head.appendChild = customAppendChild;
    }
    console.log('[Launcher] You are running non stable version: ', version);
  }

  document.body.appendChild(script);
}

initLauncher();
